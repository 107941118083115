import React, { useEffect } from 'react';
import { Container, Typography, Box, Card, CardContent, CardMedia, Divider, Button, Grid } from '@mui/material';
import './RetailPortfolio.css';
import shadesbyericImage from './img-assets/shadesbyeric.png';
import himadriImage from './img-assets/himadri.png';

const RetailPortfolio = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container className="portfolio-container">
      <Box my={4} textAlign="center">
        <Typography variant="h3" gutterBottom className="portfolio-title">Retail Portfolio</Typography>
        <Typography variant="body1" paragraph className="portfolio-description">
          Explore our showcased applications developed for the retail industry. Our solutions are designed to enhance user experience, streamline transactions, and drive in-store and online sales.
        </Typography>

        <Divider className="portfolio-divider" />

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Card className="app-card">
              <CardMedia
                component="img"
                height="300"
                image={shadesbyericImage}
                alt="Shades by Eric"
                className="app-image"
              />
              <CardContent className="app-content">
                <Typography variant="h5" component="div" className="app-title">Shades by Eric</Typography>
                <Typography variant="body2" color="textSecondary" className="app-subtitle">Home Decor Retail</Typography>
                <Typography variant="body2" paragraph className="app-detail">
                  Platform: Web
                </Typography>
                <Typography variant="body2" paragraph className="app-detail">
                  Services: Retail & Online Paint Collection Store
                </Typography>
                <Button variant="contained" color="primary" href="https://shadesbyerickuster.com/" target="_blank" rel="noopener" className="app-link">
                  Visit Website
                </Button>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card className="app-card">
              <CardMedia
                component="img"
                height="300"
                image={himadriImage}
                alt="Label Himadri"
                className="app-image"
              />
              <CardContent className="app-content">
                <Typography variant="h5" component="div" className="app-title">Label Himadri</Typography>
                <Typography variant="body2" color="textSecondary" className="app-subtitle">Fashion Retail</Typography>
                <Typography variant="body2" paragraph className="app-detail">
                  Platform: Web
                </Typography>
                <Typography variant="body2" paragraph className="app-detail">
                  Services: Retail & Online Fashion Store
                </Typography>
                <Button variant="contained" color="primary" href="https://www.labelhimadri.com/" target="_blank" rel="noopener" className="app-link">
                  Visit Website
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default RetailPortfolio;
