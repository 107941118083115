// src/components/News.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './News.css';

const News = () => {
  return (
    <Container className="news-container">
      <Row className="news-header">
        <Col>
          <h1>Latest News</h1>
          <p>Stay updated with the latest news and updates from Stratagemix Consulting.</p>
        </Col>
      </Row>
      <Row className="news-content">
        <Col>
          <div className="coming-soon">
            <h2>Coming Soon</h2>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default News;
