import React, { useEffect } from 'react';
import { Container, Typography, Box, Card, CardContent, CardMedia, Divider, Button, Grid } from '@mui/material';
import './ConsultingPortfolio.css';
import milkystartsImage from './img-assets/milkystarts.png';
import techabledImage from './img-assets/techabled.png';

const ConsultingPortfolio = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container className="portfolio-container">
      <Box my={4} textAlign="center">
        <Typography variant="h3" gutterBottom className="portfolio-title">Consulting Services Portfolio</Typography>
        <Typography variant="body1" paragraph className="portfolio-description">
          Explore our showcased applications developed for the consulting industry. Our solutions are designed to provide expert guidance, enhance client experience, and support professional growth.
        </Typography>

        <Divider className="portfolio-divider" />

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Card className="app-card">
              <CardMedia
                component="img"
                height="300"
                image={milkystartsImage}
                alt="MilkyStarts"
                className="app-image"
              />
              <CardContent className="app-content">
                <Typography variant="h5" component="div" className="app-title">MilkyStarts</Typography>
                <Typography variant="body2" color="textSecondary" className="app-subtitle">Lactation Consultant</Typography>
                <Typography variant="body2" paragraph className="app-detail">
                  Platform: Web
                </Typography>
                <Typography variant="body2" paragraph className="app-detail">
                  Services: Breastfeeding Support, Lactation Consultation
                </Typography>
                <Button variant="contained" color="primary" href="https://milkystarts.com/" target="_blank" rel="noopener" className="app-link">
                  Visit Website
                </Button>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card className="app-card">
              <CardMedia
                component="img"
                height="300"
                image={techabledImage}
                alt="Techabled Consulting"
                className="app-image"
              />
              <CardContent className="app-content">
                <Typography variant="h5" component="div" className="app-title">Techabled Consulting</Typography>
                <Typography variant="body2" color="textSecondary" className="app-subtitle">Salesforce Consulting</Typography>
                <Typography variant="body2" paragraph className="app-detail">
                  Platform: Web
                </Typography>
                <Typography variant="body2" paragraph className="app-detail">
                  Services: Salesforce Implementation, Managed Services
                </Typography>
                <Button variant="contained" color="primary" href="https://techabled.in/" target="_blank" rel="noopener" className="app-link">
                  Visit Website
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default ConsultingPortfolio;
