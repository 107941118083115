import React, { useEffect } from 'react';
import { Container, Typography, Box, Card, CardContent, CardMedia, Divider, Button } from '@mui/material';
import './RealEstatePortfolio.css';
import justhomzImage from './img-assets/justhomz.svg';

const RealEstatePortfolio = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container className="portfolio-container">
      <Box my={4} textAlign="center">
        <Typography className="portfolio-title">Real Estate Portfolio</Typography>
        <Typography variant="body1" paragraph className="portfolio-description">
          Explore our showcased applications developed for the real estate industry. Our solutions are designed to enhance user experience, increase engagement, and drive sales.
        </Typography>

        <Divider className="portfolio-divider" />

        <Card className="app-card">
          <CardMedia
            component="img"
            height="300"
            image={justhomzImage}
            alt="JustHomz App"
            className="app-image"
          />
          <CardContent className="app-content">
            <Typography variant="h5" component="div" className="app-title">JustHomz 2023</Typography>
            <Typography variant="body2" color="textSecondary" className="app-subtitle">By BlackOpal Group</Typography>
            <Typography variant="body2" paragraph className="app-detail">
              Type: Real Estate Location-based properties
            </Typography>
            <Typography variant="body2" paragraph className="app-detail">
              Platform: Android & iOS (Flutter)
            </Typography>
            <Typography variant="body2" paragraph className="app-detail">
              Rating: 4.9 Stars
            </Typography>
            <Button variant="contained" color="primary" href="https://play.google.com/store/apps/details?id=com.blackopal.justhomz&hl=en_US" target="_blank" rel="noopener" className="app-link">
              View on Google Play Store
            </Button>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default RealEstatePortfolio;
