import React, { useEffect } from 'react';
import { Container, Typography, Box, Card, CardContent, CardMedia, Divider, Button, Grid } from '@mui/material';
import './HomePortfolio.css';
import homedecorImage from './img-assets/homedecor.png';
import zarahomestudioImage from './img-assets/zarahomestudio.png';

const HomePortfolio = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container className="portfolio-container">
      <Box my={4} textAlign="center">
        <Typography variant="h3" gutterBottom className="portfolio-title">Home Services Portfolio</Typography>
        <Typography variant="body1" paragraph className="portfolio-description">
          Explore our showcased applications developed for the home services industry. Our solutions are designed to enhance living spaces, streamline home improvement processes, and provide exceptional design services.
        </Typography>

        <Divider className="portfolio-divider" />

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Card className="app-card">
              <CardMedia
                component="img"
                height="300"
                image={homedecorImage}
                alt="The Home Decor"
                className="app-image"
              />
              <CardContent className="app-content">
                <Typography variant="h5" component="div" className="app-title">The Home Decor</Typography>
                <Typography variant="body2" color="textSecondary" className="app-subtitle">Home Decoration Services</Typography>
                <Typography variant="body2" paragraph className="app-detail">
                  Platform: Web
                </Typography>
                <Typography variant="body2" paragraph className="app-detail">
                  Services: Interior Design, Home Decoration, Renovation
                </Typography>
                <Button variant="contained" color="primary" href="https://www.thehomedecor.in/" target="_blank" rel="noopener" className="app-link">
                  Visit Website
                </Button>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card className="app-card">
              <CardMedia
                component="img"
                height="300"
                image={zarahomestudioImage}
                alt="Zara Home Studio"
                className="app-image"
              />
              <CardContent className="app-content">
                <Typography variant="h5" component="div" className="app-title">Zara Home Studio</Typography>
                <Typography variant="body2" color="textSecondary" className="app-subtitle">Home Decoration Services</Typography>
                <Typography variant="body2" paragraph className="app-detail">
                  Platform: Web
                </Typography>
                <Typography variant="body2" paragraph className="app-detail">
                  Services: Interior Design, Home Decoration, Renovation
                </Typography>
                <Button variant="contained" color="primary" href="https://zarahomestudio.com/" target="_blank" rel="noopener" className="app-link">
                  Visit Website
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default HomePortfolio;
