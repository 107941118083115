import React, { useEffect } from 'react';
import { Container, Typography, Box, Card, CardContent, CardMedia, Divider, Button, Grid } from '@mui/material';
import './TravelPortfolio.css';
import ixigoImage from './img-assets/ixigo.svg';
import hometherapyImage from './img-assets/hometherapy.png';

const TravelPortfolio = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container className="portfolio-container">
      <Box my={4} textAlign="center">
        <Typography variant="h3" gutterBottom className="portfolio-title">Travel Portfolio</Typography>
        <Typography variant="body1" paragraph className="portfolio-description">
          Explore our showcased applications developed for the travel industry. Our solutions are designed to enhance user experience, increase engagement, and drive bookings.
        </Typography>

        <Divider className="portfolio-divider" />

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Card className="app-card">
              <CardMedia
                component="img"
                height="300"
                image={ixigoImage}
                alt="IXIGO App"
                className="app-image"
              />
              <CardContent className="app-content">
                <Typography variant="h5" component="div" className="app-title">IXIGO-2018-19</Typography>
                <Typography variant="body2" color="textSecondary" className="app-subtitle">Backend Support Work</Typography>
                <Typography variant="body2" paragraph className="app-detail">
                  Type: Travel & Bookings
                </Typography>
                <Typography variant="body2" paragraph className="app-detail">
                  Platform: Android
                </Typography>
                <Typography variant="body2" paragraph className="app-detail">
                  Rating: 4.2 Stars
                </Typography>
                <Typography variant="body2" paragraph className="app-detail">
                  Downloads: 10+ Million
                </Typography>
                <Button variant="contained" color="primary" href="https://play.google.com/store/apps/details?id=com.ixigo" target="_blank" rel="noopener" className="app-link">
                  View on Google Play Store
                </Button>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card className="app-card">
              <CardMedia
                component="img"
                height="300"
                image={hometherapyImage}
                alt="Home Therapy"
                className="app-image"
              />
              <CardContent className="app-content">
                <Typography variant="h5" component="div" className="app-title">Home Therapy</Typography>
                <Typography variant="body2" color="textSecondary" className="app-subtitle">Travel & Lodging</Typography>
                <Typography variant="body2" paragraph className="app-detail">
                  Platform: Web
                </Typography>
                <Typography variant="body2" paragraph className="app-detail">
                  Services: Lodging, Comfort Living, Corporate Association
                </Typography>
                <Button variant="contained" color="primary" href="https://hometherapy.ae/" target="_blank" rel="noopener" className="app-link">
                  Visit Website
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default TravelPortfolio;
