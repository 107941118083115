import React, { useState, useEffect } from 'react';
import { Container, Grid, Box, Typography, Avatar, Dialog, DialogContent, DialogTitle, IconButton, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Lottie from 'lottie-react';
import './About.css';
import aboutImage from '../assets/about_img_4.webp'; // Adjust the path as needed
import teamMember1 from '../assets/cshah_HS.JPG'; // Adjust the path as needed
import teamMember2 from '../assets/gunjan_HS.jpeg'; // Adjust the path as needed
import teamMember3 from '../assets/ashish_HS.jpg'; // Adjust the path as needed
import teamMember4 from '../assets/manish_HS.webp'; //
import companyValuesAnimation from '../assets/company_value.json'; // Path to Lottie animation file

const About = () => {
  const [open, setOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState({});

  const teamMembers = [
    {
      name: 'Dr. Chinmay Shah',
      role: 'Co-Founder & CEO',
      image: teamMember1,
      description: 'Dr. Chinmay Shah is the CEO & founder of Stratagemix Consulting with over ten years of industry and research experience in developing optimization and control algorithms for energy systems. He has held/holds professional roles at notable corporations such as Caterpillar and L&T. Chinmay received his PhD in electrical engineering from University of Alaska Fairbanks, where he performed research at Alaska Center for Energy and Power (ACEP) & National Renewable Energy Laboratory related to the development of optimization algorithms for energy systems.'
    },
    {
      name: 'Gunjan Thekdi',
      role: 'Co-Founder & CBO',
      image: teamMember2,
      description: 'Gunjan Thekdi is the CBO and co-founder of Stratagemix Consulting with a decade of experience as a project estimator and accountant for small to medium scale businesses and city governments.'
    },
    {
      name: 'Ashish Ranjan',
      role: 'Consultant & Advisor',
      image: teamMember3,
      description: 'Ashish Ranjan is the seasoned performance marketer with over 15 years of experience in customer service, traditional marketing, and digital marketing. Previously served as a Google Ads account manager and has held professional roles at prestigious companies such as IBM, Genpact, Amazon, and Orange.'
    },
    {
      name: 'Manish Kumar',
      role: 'Consultant',
      image: teamMember4,
      description: 'Manish Kumar is a creative techie with over 11 years of experience in design and development. Holds a degree in Computer Applications, an MBA, a PGDBM, and a Degree in Entrepreneurship Management from EDII. Has work experience at companies such as Amazon, Gemini Corp NV Belgium, and Homekey.'
    }
  ];

  const handleClickOpen = (member) => {
    setSelectedMember(member);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Ensure the page opens at the top

    const updateContentSize = () => {
      const container = document.querySelector('.about-container');
      const img = document.querySelector('.img-fluid');
      const teamSection = document.querySelector('.about-team-section');
      const valuesSection = document.querySelector('.about-values');

      if (window.innerWidth > 768) {
        const containerWidth = container.offsetWidth;

        img.style.height = 'auto';
        teamSection.style.width = '100%';
        valuesSection.style.width = '100%';
      }
    };

    // Initial update
    updateContentSize();

    // Update on window resize
    window.addEventListener('resize', updateContentSize);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('resize', updateContentSize);
    };
  }, []);

  return (
    <Container className="about-container">
      <Box className="about-header">
        <Typography variant="h1">About Stratagemix Consulting</Typography>
      </Box>
      <Grid container spacing={4} className="about-content">
        <Grid item md={6}>
          <Typography>
            Welcome to Stratagemix Consulting - your one-stop solution for business excellence. At Stratagemix Consulting, we understand that in today's rapidly evolving business landscape, staying ahead of the competition requires more than just traditional methods. Our comprehensive suite of consulting services is designed to drive growth, optimize resources, streamline operations, and enhance sustainability by leveraging cutting-edge strategies and technologies. Whether you need expertise in <strong>energy and resource optimization, IT systems and website development, accounting and bookkeeping, digital marketing, accessory branding, and/or decarbonization/sustainability consulting</strong>, partner with us to help you achieve your goals.
          </Typography>
          <Typography>
            Contact us today to discover how Stratagemix Consulting can elevate your business.
          </Typography>
          <div className="button-group">
            <Button variant="contained" color="primary" href="/contact" className="contact-us-button">
              Contact Us
            </Button>
            <Button variant="contained" color="secondary" href="/services" className="explore-services-button">
              Explore Our Services
            </Button>
          </div>
        </Grid>
        <Grid item md={6}>
          <img src={aboutImage} alt="About Us" className="img-fluid" />
        </Grid>
      </Grid>
      <div className="about-team-section-container">
        <div className="about-team-section">
          <Container>
            <Typography variant='h2'>
              Our Team
            </Typography>
            <Grid container spacing={4} className="about-bio-container">
              {teamMembers.map((member) => (
                <Grid item xs={12} md={4} key={member.name} className="about-bio">
                  <div className="team-member" onClick={() => handleClickOpen(member)}>
                    <img src={member.image} alt={member.name} className="team-avatar" />
                    <Typography variant="h5" className="team-name">{member.name}</Typography>
                    <Typography variant="body2" className="team-designation">{member.role}</Typography>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Container>
        </div>
      </div>
      <Box className="about-values">
        <Typography variant="h3" align="center" gutterBottom>Our Values</Typography>
        <Box className="about-values-disp">
          <Lottie animationData={companyValuesAnimation} style={{ width: '50vw' }} />
        </Box>
      </Box>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>
          {selectedMember.name}
          <IconButton aria-label="close" onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
            <Avatar src={selectedMember.image} alt={selectedMember.name} sx={{ width: 120, height: 120, marginBottom: 2 }} />
            <Typography variant="h5">{selectedMember.name}</Typography>
            <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 2 }}>{selectedMember.role}</Typography>
            <Typography variant="body1">{selectedMember.description}</Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default About;
