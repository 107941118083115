import React, { useEffect } from 'react';
import { Container, Typography, Box, Card, CardContent, CardMedia, Divider, Button, Grid } from '@mui/material';
import './CreativePortfolio.css';
import glasssutraImage from './img-assets/glasssutra.png';

const CreativePortfolio = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container className="portfolio-container">
      <Box my={4} textAlign="center">
        <Typography variant="h3" gutterBottom className="portfolio-title">Creative Services Portfolio</Typography>
        <Typography variant="body1" paragraph className="portfolio-description">
          Explore our showcased applications developed for the creative industry. Our solutions are designed to inspire, engage, and enhance artistic expressions.
        </Typography>

        <Divider className="portfolio-divider" />

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Card className="app-card">
              <CardMedia
                component="img"
                height="300"
                image={glasssutraImage}
                alt="GlassSutra"
                className="app-image"
              />
              <CardContent className="app-content">
                <Typography variant="h5" component="div" className="app-title">GlassSutra</Typography>
                <Typography variant="body2" color="textSecondary" className="app-subtitle">Glass Art Studio</Typography>
                <Typography variant="body2" paragraph className="app-detail">
                  Platform: Web
                </Typography>
                <Typography variant="body2" paragraph className="app-detail">
                  Services: Glass Art, Workshops, Accessories
                </Typography>
                <Button variant="contained" color="primary" href="https://glasssutra.com/" target="_blank" rel="noopener" className="app-link">
                  Visit Website
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default CreativePortfolio;
