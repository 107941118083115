// src/components/ServiceDetails.js
import React, { useState } from 'react';
import './ServiceDetails.css';
import { motion, AnimatePresence } from 'framer-motion';
import a from "./images/a.jpg";
import b from "./images/b.jpg";
import c from "./images/c.jpg";
import d from "./images/d.jpg";
import e from "./images/e.jpg";
import f from "./images/f.jpg";
import g from "./images/g.jpg";
import h from "./images/h.jpeg";

const services = [
  {
    title: "Energy & Resource Optimization",
    description: "We can optimize your energy and resource usage.",
    image: a,
    details: (
      <div className="dialog-details">
        <h3>Services</h3>
        <p>Energy Usage Optimization:</p>
        <ul>
          <li>Energy Audits: Comprehensive audits to assess current energy usage and identify inefficiencies.</li>
          <li>Benchmarking: Comparing your energy usage against industry standards to identify improvement areas & energy-saving opportunities.</li>
          <li>Smart Solutions: Solutions for integration of smart technologies for efficient energy distribution, usage, and monitoring.</li>
          </ul>
        <p>Innovative Solutions:</p>
        <ul>
          <li>Predictive Analytics: Using AI and machine learning to predict and optimize energy usage patterns.</li>
          <li>Demand Response Programs: Implementing demand response strategies to reduce peak energy consumption.</li>
        </ul>
        <p>Resource Usage Optimization:</p>
        <ul>
          <li>Materials Management: Techniques for efficient use and management of raw materials and resources.</li>
        </ul>
        <p>Consultation Services:</p>
        <ul>
          <li>Strategic Planning: Developing comprehensive plans for energy and resource optimization.</li>
          <li>Feasibility Studies: Assessing the feasibility and potential benefits of proposed solutions.</li>
        </ul>
        <p>Continuous Support:</p>
        <ul>
          <li>Monitoring and Reporting: Ongoing monitoring and regular reporting on energy and resource usage.</li>
          <li>Optimization Reviews: Periodic reviews to identify new optimization opportunities.</li>
        </ul>
        <h3>Custom Algorithm Development:</h3>
        <p>Industry Specific Tailored Solutions:</p>
        <ul>
          <li>Energy Savings Optimization Algorithms: Custom algorithms to optimize energy consumption based on specific business needs and conditions.</li>
          <li>Resource Allocation Algorithms: Algorithms designed to efficiently allocate resources, minimizing waste and maximizing productivity.</li>
          </ul>
        <h3>Technologies and Tools Development:</h3>
        <p>Tools:</p>
        <ul>
          <li>Advanced Data Analytics Platforms: Tools for collecting, analyzing, and visualizing energy and resource usage data to uncover insights and drive efficiency. Developing and monitoring key performance indicators (KPI) for continuous improvement.</li>
          <li>Machine Learning Models: Advanced models for predicting usage patterns and identifying optimization opportunities.</li>
        </ul>
        <p>Software Solutions:</p>
        <ul>
          <li>Energy Monitoring Software: Platforms/Dashboards for real-time monitoring and reporting of energy usage and savings.</li>
          <li>Resource Management Software: Tools for tracking and optimizing the use of resources.</li>
          <li>Integration with Existing Systems: Ensuring compatibility and seamless integration with clients’ existing systems and infrastructure.</li>
        </ul>
      </div>
    )
  },
  {
    title: "Website & Mobile App Development",
    description: "We create modern and progressive web applications, websites & mobile applications for both Android and iOS platforms.",
    image: b,
    details: (
      <div className="dialog-details">
        <h3>Website Development</h3>
        <p>Custom Website Development:</p>
        <ul>
          <li>Technologies: HTML, CSS, JavaScript, PHP, Python, Ruby on Rails, etc.</li>
          <li>Frameworks & CMS: React, Angular, Vue.js, WordPress, Joomla, Drupal.</li>
          <li>E-commerce Solutions: Shopify, WooCommerce, Magento, custom e-commerce platforms.</li>
          <li>Responsive Design: Ensuring websites work seamlessly on all devices.</li>
          <li>SEO Optimization: Implementing best practices for search engine visibility.</li>
          <li>Performance Optimization: Techniques for fast loading times and smooth performance.</li>
        </ul>
        <p>Innovative Solutions:</p>
        <ul>
          <li>Progressive Web Apps (PWAs): Combining the best of web and mobile apps.</li>
          <li>Headless CMS: Decoupling the backend from the frontend for more flexibility.</li>
          <li>Static Site Generators: For lightning-fast websites with frameworks like Gatsby and Next.js.</li>
        </ul>
        <h3>Mobile App Development</h3>
        <p>Native App Development:</p>
        <ul>
          <li>Platforms: iOS (Swift, Objective-C), Android (Kotlin, Java).</li>
          <li>Performance: High-performance apps optimized for each platform.</li>
        </ul>
        <p>Cross-Platform App Development:</p>
        <ul>
          <li>Frameworks: React Native, Flutter, Xamarin.</li>
          <li>Benefits: Faster development cycles, cost-effectiveness, and wider reach.</li>
        </ul>
        <p>Hybrid App Development:</p>
        <ul>
          <li>Technologies: Ionic, Cordova.</li>
          <li>Advantages: Combining native and web app features for flexibility and cost savings.</li>
        </ul>
        <p>Innovative Solutions:</p>
        <ul>
          <li>AR/VR Integration: Augmented and virtual reality applications.</li>
          <li>IoT Integration: Connecting apps with IoT devices for smart solutions.</li>
          <li>AI & ML Integration: Adding AI features like chatbots, predictive analytics, and personalization.</li>
        </ul>
        <h3>Maintenance and Support</h3>
        <p>Ongoing Maintenance:</p>
        <ul>
          <li>Services: Regular updates, security patches, bug fixes.</li>
          <li>Monitoring: 24/7 monitoring and support.</li>
        </ul>
        <p>Performance Tuning:</p>
        <ul>
          <li>Optimization: Regular performance checks and optimizations.</li>
        </ul>
        <h3>Consultation</h3>
        <p>Digital Strategy:</p>
        <ul>
          <li>Market Analysis: Understanding market trends and competition.</li>
          <li>Roadmap Development: Creating a clear plan for digital transformation.</li>
        </ul>
        <p>Technology Consultation:</p>
        <ul>
          <li>Platform Selection: Choosing the right technologies for your project.</li>
          <li>Architecture Design: Designing scalable and secure architectures.</li>
        </ul>
        <h3>Additional Services</h3>
        <p>API Development & Integration:</p>
        <ul>
          <li>Custom APIs: Developing APIs for seamless integration between systems.</li>
          <li>Third-Party Integrations: Integrating with popular services (payment gateways, social media, etc.).</li>
        </ul>
        <p>Content Management Systems (CMS):</p>
        <ul>
          <li>Custom CMS Solutions: Building tailored CMS platforms for specific needs.</li>
          <li>Headless CMS: Providing flexibility in content delivery across different channels.</li>
        </ul>
      </div>
    )
  },
  {
    title: "UI/UX Design",
    description: "We provide professional UI/UX design services to ensure user satisfaction.",
    image: c,
    details: (
      <div className="dialog-details">
        <h3>UI/UX Design</h3>
        <p>User Research:</p>
        <ul>
          <li>Techniques: Surveys, interviews, usability testing.</li>
          <li>Goals: Understanding user needs and behaviors.</li>
        </ul>
        <p>Wireframing & Prototyping:</p>
        <ul>
          <li>Tools: Sketch, Figma, Adobe XD.</li>
          <li>Benefits: Visualizing and iterating design ideas quickly.</li>
        </ul>
        <p>Visual Design:</p>
        <ul>
          <li>Principles: Color theory, typography, branding.</li>
          <li>Outcomes: Engaging, aesthetically pleasing interfaces.</li>
        </ul>
        <p>Innovative Solutions:</p>
        <ul>
          <li>Design Systems: Creating reusable components for consistency.</li>
          <li>Motion Design: Using animations to enhance user experience.</li>
          <li>Voice UI: Designing interfaces for voice-activated devices.</li>
        </ul>
      </div>
    )
  },
  {
    title: "Digital Marketing",
    description: "We offer digital marketing services to boost your online presence.",
    image: d,
    details: (
      <div className="dialog-details">
        <div className='dialog-details-custom'>
          <p>Our mission is to drive business growth through innovative, data-driven digital marketing strategies. We deliver personalized solutions tailored to each client's unique goals, ensuring measurable results and sustainable success. Committed to integrity, transparency, and collaboration, we stay ahead with the latest tools and techniques. Together, we build lasting partnerships for long-term growth and mutual success.</p>
        </div>
        <h3>Services</h3>
        <p>Search Engine Optimization (SEO):</p>
        <ul>
          <li>Technical SEO: Site audits, on-page optimization, off-page optimization, and technical fixes to improve search engine visibility.</li>
          <li>Content SEO: Keyword research, content strategy & creation, and optimization to drive organic traffic.</li>
          <li>Link Building: Ethical link-building strategies to enhance domain authority.</li>
          <li>Local SEO: Optimizing for local search results to attract nearby customers.</li>
          <li>AI-Driven SEO: Using AI tools to predict trends and optimize content.</li>
          <li>Voice Search Optimization: Tailoring SEO strategies for voice-activated search devices.</li>
        </ul>
        <p>Pay-Per-Click (PPC) Advertising:</p>
        <ul>
          <li>Campaign Strategy: Developing and managing PPC campaigns on platforms like Google Ads, Bing Ads, Snapchat ads, Meta ads, and other social media.</li>
          <li>Ad Creation: Designing compelling ad copy and visuals to maximize click-through rates.</li>
          <li>Bid Management: Optimizing bids to ensure cost-effective advertising.</li>
          <li>Analytics and Reporting: Detailed performance reports and insights.</li>
          <li>AI-Powered Bidding: Using AI to automate and optimize bidding strategies.</li>
          <li>Advanced Targeting: Implementing advanced targeting options such as remarketing and custom audiences.</li>
        </ul>
        <p>Social Media Marketing:</p>
        <ul>
          <li>Platform Management: Creating and managing profiles on platforms like Facebook, Instagram, Twitter, LinkedIn, and more.</li>
          <li>Content Creation: Developing engaging content tailored to each platform.</li>
          <li>Community Management: Engaging with followers and managing online communities.</li>
          <li>Social Media Advertising: Running targeted ad campaigns to boost visibility and engagement.</li>
          <li>Influencer Marketing: Collaborating with influencers to expand reach.</li>
          <li>Social Listening Tools: Using advanced tools to monitor brand mentions and sentiment.</li>
        </ul>
        <p>Content Marketing:</p>
        <ul>
          <li>Strategy Development: Creating comprehensive content strategies aligned with business goals.</li>
          <li>Content Creation: Producing high-quality blog posts, articles, infographics, videos, and more.</li>
          <li>Content Distribution: Promoting content through various channels to reach the target audience.</li>
          <li>Content Performance Analysis: Measuring the effectiveness of content and optimizing accordingly.</li>
          <li>Interactive Content: Creating interactive content such as quizzes, polls, and interactive infographics.</li>
          <li>Personalized Content: Using data to deliver personalized content experiences.</li>
        </ul>
        <p>Email Marketing:</p>
        <ul>
          <li>Campaign Strategy: Developing targeted email marketing campaigns.</li>
          <li>List Management: Building and segmenting email lists for targeted communication.</li>
          <li>Email Design: Crafting visually appealing and responsive email templates.</li>
          <li>Automation: Setting up automated email sequences for onboarding, nurturing, and retention.</li>
          <li>AI-Powered Personalization: Using AI to personalize email content and subject lines.</li>
          <li>Behavioral Targeting: Triggering emails based on user behavior and engagement.</li>
        </ul>
        <p>Web Analytics and Reporting:</p>
        <ul>
          <li>Setup and Configuration: Setting up Google Analytics, Tag Manager, and other tracking tools.</li>
          <li>Data Analysis: Analyzing website traffic, user behavior, and conversion rates.</li>
          <li>Reporting Dashboards: Creating custom dashboards for real-time insights.</li>
          <li>A/B Testing: Conducting experiments to optimize website elements and campaigns.</li>
          <li>Predictive Analytics: Using AI to predict future trends and customer behavior.</li>
          <li>Advanced Segmentation: Detailed segmentation of audiences for more precise targeting.</li>
        </ul>
        <p>Custom Digital Marketing Solutions:</p>
        <ul>
          <li>Tailored Strategies: Developing bespoke digital marketing strategies based on client-specific goals and industry.</li>
          <li>Multichannel Campaigns: Coordinating efforts across multiple digital channels for cohesive campaigns.</li>
          <li>Marketing Automation: Implementing automation tools to streamline marketing efforts and enhance efficiency.</li>
        </ul>
        <h3>Technologies and Tools</h3>
        <ul>
          <li>Marketing Automation Platforms: Tools like HubSpot, Marketo, and Pardot for automated marketing tasks.</li>
          <li>SEO Tools: Meta SEO Inspector, SEMrush, Yoast SEO, Ahrefs, Heap, and other SEO tools for research and optimization.</li>
          <li>Social Media Tools: Hootsuite, Buffer, Sprout Social for managing social media presence.</li>
          <li>Content Management Systems (CMS): WordPress, Drupal, and custom CMS solutions for content creation and management.</li>
          <li>Email Marketing Platforms: MailChimp, Constant Contact, SendinBlue for email campaigns.</li>
          <li>Analytics Tools: Google Analytics, Adobe Analytics, HUBspot Analytics, Shopify Analytics, and other tools for tracking and analyzing data.</li>
          <li>Business Intelligence Tools: Tableau, Power BI, Looker, and other platforms that offers powerful analytics and business intelligence capabilities.</li>
        </ul>
        <h3>Consultation and Support</h3>
        <ul>
          <li>Strategic Consultation: Offering consultations to develop custom digital marketing strategies.</li>
          <li>Choose Right Channels and Platforms: Offering consultation to help you pick the right channels and platforms to maximize the outcomes of your investment in digital marketing.</li>
          <li>Continuous Support: Ongoing support to ensure the success and optimization of digital marketing efforts.</li>
        </ul>
      </div>
    )
  },
  {
    title: "Accounting/Bookkeeping",
    description: "We can handle your finances, record keeping, and help you maximize your revenue.",
    image: e,
    details: (
      <div className="dialog-details">
        <h3>Services</h3>
        <p>Bookkeeping:</p>
        <ul>
          <li>Daily Transaction Recording: Recording daily financial transactions including sales, purchases, receipts, and payments.</li>
          <li>Bank Reconciliation: Ensuring that bank statements match company records, identifying discrepancies, and reconciling differences.</li>
          <li>Accounts Payable and Receivable: Managing payables and receivables to maintain healthy cash flow.</li>
          <li>General Ledger Maintenance: Maintaining and updating the general ledger to ensure accuracy and completeness.</li>
        </ul>
        <p>Financial Reporting:</p>
        <ul>
          <li>Monthly/Quarterly Financial Statements: Preparing income statements, balance sheets, and cash flow statements.</li>
          <li>Custom Reports: Creating custom financial reports tailored to specific business needs.</li>
          <li>Budgeting and Forecasting: Assisting with budget preparation and financial forecasting to guide business planning.</li>
          <li>Performance Analysis: Analyzing financial performance and providing insights for improvement.</li>
          <li>Dashboard Reporting: Providing interactive dashboards for real-time financial insights.</li>
          <li>Predictive Analytics: Using AI to predict future financial trends and outcomes.</li>
        </ul>
        <p>Cash Flow Management:</p>
        <ul>
          <li>Cash Flow Forecasting: Predicting future cash flow based on historical data and upcoming expenses.</li>
          <li>Working Capital Management: Optimizing the use of working capital to ensure business liquidity.</li>
          <li>Expense Tracking: Monitoring and controlling business expenses to improve profitability.</li>
          <li>AI-Based Cash Flow Tools: Implementing AI tools to automate and enhance cash flow forecasting.</li>
        </ul>
        <p>Account Management</p>
        <ul>
          <li>Accounts Reconciliation: Regular reconciliation of all accounts to ensure accuracy.</li>
          <li>Vendor and Customer Management: Maintaining detailed records of vendor and customer transactions.</li>
          <li>Payroll Processing: Managing payroll to ensure timely and accurate employee payments.</li>
          <li>Automated Payroll Systems: Using automated systems for efficient payroll processing.</li>
        </ul>
        <p>Custom Accounting Solutions:</p>
        <ul>
          <li>Tailored Services: Providing customized accounting and bookkeeping solutions based on specific client requirements.</li>
          <li>Industry-Specific Accounting: Offering specialized accounting services for various industries such as retail, e-commerce, and consulting services etc.</li>
          <li>Technology Integration: Integrating accounting software with other business systems for seamless operations.</li>
        </ul>
        <h3>Technologies and Tools</h3>
        <ul>
          <li>Accounting Software: Proficiency in leading accounting software such as QuickBooks and Springbrook.</li>
          <li>Data Analytics Tools: Implementing tools like Power BI and Tableau for advanced financial analysis.</li>
        </ul>
        <h3>Consultation and Support</h3>
        <ul>
          <li>Strategic Consultation: Offering consultations to develop effective accounting and bookkeeping strategies tailored to client needs.</li>
          <li>Continuous Support: Ongoing support to ensure the accuracy and efficiency of accounting processes.</li>
        </ul>
      </div>
    )
  },
  {
    title: "Grant/Proposal & Technical Writing",
    description: "We can elevate your business with our specialized grant/proposal and technical writing services, delivering clear, compelling, and professionally crafted documents to support your goals and initiatives.",
    image: f,
    details: (
      <div className="dialog-details">
        <h3>Services</h3>
        <p>Grant Writing:</p>
        <ul>
          <li>Research and Identification: Identifying relevant grant opportunities and funding sources.</li>
          <li>Proposal Development: Crafting compelling and persuasive grant proposals that align with funding requirements.</li>
          <li>Review and Editing: Providing thorough reviews and edits to ensure clarity, coherence, and compliance.</li>
          <li>Submission Management: Handling the submission process to ensure all deadlines and requirements are met.</li>
        </ul>
        <p>Proposal Writing:</p>
        <ul>
          <li>Business Proposals: Creating comprehensive business proposals to secure partnerships, contracts, and investments.</li>
          <li>Project Proposals: Developing detailed project proposals, including objectives, methodology, timelines, and deliverables.</li>
          <li>RFP Responses: Crafting tailored responses to Requests for Proposals (RFPs) that highlight your unique value proposition.</li>
          <li>Executive Summaries: Writing concise and impactful executive summaries to capture the essence of your proposal.</li>
        </ul>
        <p>Technical Writing:</p>
        <ul>
          <li>Technical Documentation: Developing clear and comprehensive technical documents, including user manuals, SOPs, and technical reports.</li>
          <li>White Papers: Writing in-depth white papers that demonstrate expertise and thought leadership.</li>
          <li>Case Studies: Crafting detailed case studies that showcase successful projects and solutions.</li>
          <li>Product Documentation: Creating detailed product documentation, including specifications, installation guides, and troubleshooting manuals.</li>
        </ul>
        <p>Editing and Proofreading:</p>
        <ul>
          <li>Detailed Review: Thoroughly reviewing documents for clarity, coherence, and correctness.</li>
          <li>Technical Accuracy: Ensuring technical content is accurate and aligns with industry standards.</li>
          <li>Language and Style: Refining language and style to suit the target audience and purpose.</li>
          <li>Formatting and Compliance: Ensuring documents adhere to required formats and guidelines.</li>
        </ul>
        <p>Custom Writing Solutions:</p>
        <ul>
          <li>Tailored Content: Developing custom content based on specific client requirements and industry needs.</li>
          <li>Digital Content: Creating digital content, such as eBooks, webinars, and online courses, to complement traditional writing services.</li>
        </ul>
        <h3>Technologies and Tools</h3>
        <ul>
          <li>Document Management Systems: Tools like Google Workspace, Microsoft Office 365, Overleaf, and Adobe Acrobat for document creation, collaboration, and management.</li>
          <li>Project Management Tools: Platforms like Trello, Asana, and Monday.com for tracking writing projects and deadlines.</li>
          <li>Research Databases: Access to databases such as PubMed, IEEE Xplore, and Google Scholar for thorough and credible research.</li>
        </ul>
        <h3>Consultation and Support</h3>
        <ul>
          <li>Strategic Consultation: Offering consultations to develop effective writing strategies tailored to client needs.</li>
          <li>Continuous Support: Ongoing support to ensure the success and optimization of writing projects.</li>
        </ul>
      </div>
    )
  },
  {
    title: "Graphic Design, Accessory Design, and Branding",
    description: "We offer solutions to brand your business.",
    image: g,
    details: (
      <div className="dialog-details">
        <div className='dialog-details-custom'>
          <p>We provide customized design solutions based on specific client needs and preferences and also involve clients in the design process to ensure the final product aligns with their vision..</p>
        </div>
        <h3>Services</h3>
        <p>Graphic Design:</p>
        <ul>
          <li>Logo Design: Creating unique and impactful logos that represent your brand’s identity.</li>
          <li>Brand Identity Design: Developing comprehensive brand identities including color schemes, typography, and visual elements.</li>
          <li>Print Design: Designing brochures, flyers, posters, business cards, and other marketing materials.</li>
          <li>Digital Design: Creating graphics for websites, social media, email campaigns, and digital advertisements.</li>
          <li>Packaging Design: Designing attractive and functional packaging that enhances product appeal.</li>
        </ul>
        <p>Accessory Branding:</p>
        <ul>
          <li>Merchandise Design: Designing branded merchandise such as apparel, accessories, and promotional items.</li>
          <li>Stationery Design: Elevate your brand identity with our professionally designed stationery that reflects your brand style and professionalism.</li>
          <li>Product Customization: Offering custom designs for products to create unique and personalized branding.</li>
          <li>Label and Tag Design: Creating labels and tags that enhance product presentation and branding.</li>
          <li>Point of Sale (POS) Materials: Designing displays, banners, and other POS materials to attract customers and boost sales.</li>
        </ul>
        <p>Brand Strategy:</p>
        <ul>
          <li>Market Research: Conducting research to understand target audiences, competitors, and market trends.</li>
          <li>Brand Positioning: Defining and positioning your brand in the market to stand out from competitors.</li>
          <li>Brand Guidelines: Developing comprehensive brand guidelines to ensure consistency across all branding materials.</li>
          <li>Rebranding: Helping businesses rebrand to refresh their image and stay relevant in the market.</li>
          <li>Brand Storytelling: Crafting compelling brand stories that resonate with your audience.</li>
          <li>Digital Branding: Enhancing brand presence across digital channels through cohesive and engaging visuals.</li>
        </ul>
        <h3>Technologies and Tools</h3>
        <ul>
          <li>Design Software: Proficiency in industry-leading design tools such as Adobe Creative Suite (Photoshop, Illustrator, InDesign), CorelDRAW, Sketch, and Figma.</li>
          <li>Prototyping Tools: Using tools like Adobe XD, InVision, and Axure for creating interactive prototypes.</li>
          <li>3D Modeling Software: Utilizing software such as Blender, Autodesk Maya, and Cinema 4D for 3D design and modeling.</li>
          <li>Collaboration Tools: Leveraging tools like Trello, Asana, and Slack for project management and client collaboration.</li>
        </ul>
      </div>
    )
  },
  {
    title: "Decarbonization & Sustainability Consulting",
    description: "Expert decarbonization consulting services.",
    image: h,
    details: (
      <div className="dialog-details">
        <h3>Coming Soon!</h3>
      </div>
    )
  },
];

const ServiceDetails = () => {
  const [selectedService, setSelectedService] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleCardClick = (service) => {
    setSelectedService(service);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setTimeout(() => setSelectedService(null), 300); // wait for animation to complete before removing
  };

  const handleScrollToTop = () => {
    const dialogContent = document.querySelector('.dialog-content');
    dialogContent.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="service-details-container">
      <h1>Our Services</h1>
      <div className="service-cards">
        {services.map((service, index) => {
          const isEvenRow = Math.floor(index / 2) % 2 === 0;
          const isLargeCard = index % 2 === 0;
          return (
            <motion.div
              key={index}
              className={`service-card ${isLargeCard === isEvenRow ? 'large-card' : 'small-card'}`}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              layoutId={service.title}
              onClick={() => handleCardClick(service)}
            >
              <img src={service.image} alt={service.title} className="service-image" />
              <div className="service-card-content">
                <h2>{service.title}</h2>
                <p>{service.description}</p>
              </div>
            </motion.div>
          );
        })}
      </div>
      <AnimatePresence>
        {isDialogOpen && selectedService && (
          <motion.div
            className="dialog-overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            onClick={handleCloseDialog}
          >
            <motion.div
              className="dialog-content"
              layoutId={selectedService.title}
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.8 }}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="dialog-image-container">
                <img src={selectedService.image} alt={selectedService.title} className="dialog-image" />
                <div className="dialog-title-overlay">
                  <h2>{selectedService.title}</h2>
                </div>
              </div>
              <div className="dialog-details">
                {selectedService.details}
              </div>
              <button onClick={handleCloseDialog}>Close</button>
              <button className="top-button" onClick={handleScrollToTop}>Top</button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ServiceDetails;
