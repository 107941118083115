// src/components/Header.js
import React, { useState } from 'react';
import { AppBar, Toolbar, Button, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import LanguageIcon from '@mui/icons-material/Language';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GoogleIcon from '@mui/icons-material/Google';
import logo from '../assets/C_Logo-main.svg'; // Adjust the path as needed
import './Header.css';

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const list = () => (
    <List onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
      <ListItem button component={Link} to="/">
        <ListItemText primary="Home" />
      </ListItem>
      <ListItem button component={Link} to="/about">
        <ListItemText primary="About" />
      </ListItem>
      <ListItem button component={Link} to="/services">
        <ListItemText primary="Services" />
      </ListItem>
      <ListItem button component={Link} to="/contact">
        <ListItemText primary="Contact" />
      </ListItem>
      <ListItem button component={Link} to="/news">
        <ListItemText primary="News" />
      </ListItem>
      <ListItem button component="a" href="https://www.linkedin.com/company/stratagemix-consulting-llc" target="_blank" rel="noopener noreferrer">
        <LinkedInIcon />
        <ListItemText primary="LinkedIn" />
      </ListItem>
      <ListItem button component="a" href="/">
        <LanguageIcon />
        <ListItemText primary="Language" />
      </ListItem>
      <ListItem button component={Link} to="/google">
        <GoogleIcon />
        <ListItemText primary="Google" />
      </ListItem>
    </List>
  );

  return (
    <AppBar position="fixed" className="navbar">
      <Toolbar className="toolbar">
        <Link to="/" className="logo-link">
          <img src={logo} alt="STRATAGEMIX" className="header-logo" />
        </Link>
        <div className="spacer"></div>
        <div className="nav-links">
          <Button component={Link} to="/" className="nav-button">Home</Button>
          <Button component={Link} to="/about" className="nav-button">About</Button>
          <Button component={Link} to="/services" className="nav-button">Services</Button>
          <Button component={Link} to="/contact" className="nav-button">Contact</Button>
          <Button component={Link} to="/news" className="nav-button">News</Button>
          <IconButton
            component="a"
            href="https://www.linkedin.com/company/stratagemix-consulting-llc"
            target="_blank"
            rel="noopener noreferrer"
            className="icon-button"
          >
            <LinkedInIcon />
          </IconButton>
          <IconButton
            component="a"
            href="/"
            className="icon-button"
          >
            <LanguageIcon />
          </IconButton>
          <IconButton
            component={Link} 
            to="/google" 
            className="icon-button"
          >
            <GoogleIcon />
          </IconButton>
        </div>
        <IconButton
          edge="start"
          className="hamburger-icon"
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer(true)}
        >
          <MenuIcon />
        </IconButton>
        <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
          {list()}
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
