import React, { useEffect } from 'react';
import { Container, Typography, Box, Card, CardContent, CardMedia, Divider, Button, Grid } from '@mui/material';
import './EcommercePortfolio.css';
import drinkloaImage from './img-assets/drinkloa.png';
import giabatheImage from './img-assets/giabathe.png';
import faballeyImage from './img-assets/faballey.png';
import parempireImage from './img-assets/parempire.png';
import pellattImage from './img-assets/pellatt.png';
import phancyfoilImage from './img-assets/phancyfoil.png';
import oxaleatherImage from './img-assets/oxaleather.png';

const EcommercePortfolio = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container className="portfolio-container">
      <Box my={4} textAlign="center">
        <Typography variant="h3" gutterBottom className="portfolio-title">Ecommerce Portfolio</Typography>
        <Typography variant="body1" paragraph className="portfolio-description">
          Explore our showcased applications developed for the ecommerce industry. Our solutions are designed to enhance user experience, streamline transactions, and drive online sales.
        </Typography>

        <Divider className="portfolio-divider" />

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Card className="app-card">
              <CardMedia
                component="img"
                height="300"
                image={drinkloaImage}
                alt="Drink LOA"
                className="app-image"
              />
              <CardContent className="app-content">
                <Typography variant="h5" component="div" className="app-title">Drink LOA</Typography>
                <Typography variant="body2" color="textSecondary" className="app-subtitle">Beverage Ecommerce</Typography>
                <Typography variant="body2" paragraph className="app-detail">
                  Platform: Web
                </Typography>
                <Typography variant="body2" paragraph className="app-detail">
                  Services: Online Beverage Store, Subscription Services
                </Typography>
                <Button variant="contained" color="primary" href="https://drinkloa.com/" target="_blank" rel="noopener" className="app-link">
                  Visit Website
                </Button>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card className="app-card">
              <CardMedia
                component="img"
                height="300"
                image={giabatheImage}
                alt="Giabathe"
                className="app-image"
              />
              <CardContent className="app-content">
                <Typography variant="h5" component="div" className="app-title">Giabathe</Typography>
                <Typography variant="body2" color="textSecondary" className="app-subtitle">Swimwear Ecommerce</Typography>
                <Typography variant="body2" paragraph className="app-detail">
                  Platform: Web
                </Typography>
                <Typography variant="body2" paragraph className="app-detail">
                  Services: Online Swimwear Store, Seasonal Sales
                </Typography>
                <Button variant="contained" color="primary" href="https://www.giabathe.com/" target="_blank" rel="noopener" className="app-link">
                  Visit Website
                </Button>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card className="app-card">
              <CardMedia
                component="img"
                height="300"
                image={faballeyImage}
                alt="Faballey"
                className="app-image"
              />
              <CardContent className="app-content">
                <Typography variant="h5" component="div" className="app-title">Faballey</Typography>
                <Typography variant="body2" color="textSecondary" className="app-subtitle">Fashion Ecommerce</Typography>
                <Typography variant="body2" paragraph className="app-detail">
                  Platform: Web
                </Typography>
                <Typography variant="body2" paragraph className="app-detail">
                  Services: Online Fashion Store, Seasonal Sales
                </Typography>
                <Button variant="contained" color="primary" href="https://www.faballey.com/" target="_blank" rel="noopener" className="app-link">
                  Visit Website
                </Button>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card className="app-card">
              <CardMedia
                component="img"
                height="300"
                image={parempireImage}
                alt="Par Empire"
                className="app-image"
              />
              <CardContent className="app-content">
                <Typography variant="h5" component="div" className="app-title">Par Empire</Typography>
                <Typography variant="body2" color="textSecondary" className="app-subtitle">Global Trade Ecommerce</Typography>
                <Typography variant="body2" paragraph className="app-detail">
                  Platform: Web
                </Typography>
                <Typography variant="body2" paragraph className="app-detail">
                  Services: Food and Beverage Global Trade
                </Typography>
                <Button variant="contained" color="primary" href="https://parempire.com/" target="_blank" rel="noopener" className="app-link">
                  Visit Website
                </Button>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card className="app-card">
              <CardMedia
                component="img"
                height="300"
                image={pellattImage}
                alt="Pellatt Cornucopia"
                className="app-image"
              />
              <CardContent className="app-content">
                <Typography variant="h5" component="div" className="app-title">Pellatt Cornucopia</Typography>
                <Typography variant="body2" color="textSecondary" className="app-subtitle">Gift Baskets Ecommerce</Typography>
                <Typography variant="body2" paragraph className="app-detail">
                  Platform: Web
                </Typography>
                <Typography variant="body2" paragraph className="app-detail">
                  Services: Corporate and Gourmet Gift Baskets
                </Typography>
                <Button variant="contained" color="primary" href="https://pellatt.ca/" target="_blank" rel="noopener" className="app-link">
                  Visit Website
                </Button>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card className="app-card">
              <CardMedia
                component="img"
                height="300"
                image={phancyfoilImage}
                alt="Phancy Foil"
                className="app-image"
              />
              <CardContent className="app-content">
                <Typography variant="h5" component="div" className="app-title">Phancy Foil</Typography>
                <Typography variant="body2" color="textSecondary" className="app-subtitle">Hair Foil Ecommerce</Typography>
                <Typography variant="body2" paragraph className="app-detail">
                  Platform: Web
                </Typography>
                <Typography variant="body2" paragraph className="app-detail">
                  Services: Professional Hair Foil Products
                </Typography>
                <Button variant="contained" color="primary" href="https://phancyfoil.com/" target="_blank" rel="noopener" className="app-link">
                  Visit Website
                </Button>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card className="app-card">
              <CardMedia
                component="img"
                height="300"
                image={oxaleatherImage}
                alt="Oxa Leather"
                className="app-image"
              />
              <CardContent className="app-content">
                <Typography variant="h5" component="div" className="app-title">Oxa Leather</Typography>
                <Typography variant="body2" color="textSecondary" className="app-subtitle">Leather Accessories Ecommerce</Typography>
                <Typography variant="body2" paragraph className="app-detail">
                  Platform: Web
                </Typography>
                <Typography variant="body2" paragraph className="app-detail">
                  Services: Premium Leather Accessories
                </Typography>
                <Button variant="contained" color="primary" href="https://oxaleather.com/" target="_blank" rel="noopener" className="app-link">
                  Visit Website
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default EcommercePortfolio;
