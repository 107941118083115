import React, { useEffect } from 'react';
import { Container, Typography, Box, Card, CardContent, CardMedia, Divider, Button, Grid } from '@mui/material';
import './FoodPortfolio.css';
import hopsticksImage from './img-assets/hopsticks.svg';
import flourishImage from './img-assets/flourish.png';
import rmsImage from './img-assets/RMS.png';

const FoodPortfolio = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container className="portfolio-container">
      <Box my={4} textAlign="center">
        <Typography variant="h3" gutterBottom className="portfolio-title">Food Portfolio</Typography>
        <Typography variant="body1" paragraph className="portfolio-description">
          Explore our showcased applications developed for the restaurants and food delivery business. Our solutions are designed to enhance user experience, increase engagement, and streamline delivery processes.
        </Typography>

        <Divider className="portfolio-divider" />

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Card className="app-card">
              <CardMedia
                component="img"
                height="300"
                image={hopsticksImage}
                alt="Hopsticks App"
                className="app-image"
              />
              <CardContent className="app-content">
                <Typography variant="h5" component="div" className="app-title">Hopsticks</Typography>
                <Typography variant="body2" color="textSecondary" className="app-subtitle">Food Delivery</Typography>
                <Typography variant="body2" paragraph className="app-detail">
                  Platform: Android
                </Typography>
                <Typography variant="body2" paragraph className="app-detail">
                  Rating: 4.5 Stars
                </Typography>
                <Typography variant="body2" paragraph className="app-detail">
                  Downloads: 10000+
                </Typography>
                <Button variant="contained" color="primary" href="https://play.google.com/store/apps/details?id=com.hopsticks.app" target="_blank" rel="noopener" className="app-link">
                  View on Google Play Store
                </Button>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card className="app-card">
              <CardMedia
                component="img"
                height="300"
                image={flourishImage}
                alt="Flourish Pancakes"
                className="app-image"
              />
              <CardContent className="app-content">
                <Typography variant="h5" component="div" className="app-title">Flourish Pancakes</Typography>
                <Typography variant="body2" color="textSecondary" className="app-subtitle">Food Product</Typography>
                <Typography variant="body2" paragraph className="app-detail">
                  Platform: E-commerce
                </Typography>
                <Typography variant="body2" paragraph className="app-detail">
                  Category: Protein Pancakes & Waffle Mix
                </Typography>
                <Button variant="contained" color="primary" href="https://www.flourishpancakes.com/en-us" target="_blank" rel="noopener" className="app-link">
                  Visit Website
                </Button>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card className="app-card">
              <CardMedia
                component="img"
                height="300"
                image={rmsImage}
                alt="Restaurant Management System"
                className="app-image"
              />
              <CardContent className="app-content">
                <Typography variant="h5" component="div" className="app-title">Restaurant Management System</Typography>
                <Typography variant="body2" color="textSecondary" className="app-subtitle">Food Service Management</Typography>
                <Typography variant="body2" paragraph className="app-detail">
                  Platform: Web & Mobile
                </Typography>
                <Typography variant="body2" paragraph className="app-detail">
                  Features: Reservation, Orders, Kitchen, Accounts, Reports
                </Typography>
                <Button variant="contained" color="primary" href="https://www.figma.com/proto/1YIQlkrEB1vYid8EH2rIzM/final--RMS-PAGES?node-id=61-4029&scaling=min-zoom&page-id=0%3A1" target="_blank" rel="noopener" className="app-link">
                  Visit Website
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default FoodPortfolio;
