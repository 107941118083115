import React, { useState, useEffect, useRef } from 'react';
import { Container, Box, Typography, Grid, Dialog, DialogTitle, DialogContent, Button } from '@mui/material';
import Lottie from 'lottie-react';
import './Home.css';
import logo from '../assets/C_Logo_1.svg'; // Adjust the path as needed
import lottieAnimation from '../assets/home_pg_animation.json'; // Add your Lottie animation file
import buttonAnimation from '../assets/button.json'; // Add your Lottie animation file
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const videoData = [
  { title: "Finance & Insurance", src: require('../assets/industries_served/financialservices.mp4'), img: require('../assets/industries_served/financialservices.jpg'), description: "Whether you own a Accounting & Bookkeeping business, Financial Planning & Services business, Investment Advisory, or Insurance Brokerage, we deliver tailored solutions to meet your unique needs and drive sustainable growth.", industry: "finance" },
  { title: "Food Industry", src: require('../assets/industries_served/food.mp4'), img: require('../assets/industries_served/food.jpg'), description: "Whether you own Catering Services, Restaurant, Food Truck, Bakery, Cafe or a Coffee Shop, or Meal Prep & Delivery, keep providing delicious delicacies and let us take care of your unique business and operation needs and make your business profitable.", industry: "food" },
  { title: "Health & Wellness Industry", src: require('../assets/industries_served/health.mp4'), img: require('../assets/industries_served/health.jpg'), description: "Whether you are a Personal Trainer, Nutrition Consultant, Mental Health Conselor, Yoga or Pilates Studio Owner, or a health Coach, attract more clients with strategic digital marketing, user-friendly websites, accurate bookkeeping and by promoting sustainability with our help.", industry: "health" },
  { title: "Home Services Industry", src: require('../assets/industries_served/homeservices.mp4'), img: require('../assets/industries_served/homeservices.jpg'), description: "Whether you own Cleaning Services, Lawn Care & Landscaping, Home Repair & Maintenance, Pet Sitting & Grooming, and/or Home Improvement business, reach out to us for all your business and marketing needs. Grow your service business with effective digital marketing, professional website, and accurate bookkeeping. Also, enhance your operational efficiency with resource optimization, and expert team management.", industry: "home" },
  { title: "Small Scale Manufacturing", src: require('../assets/industries_served/manufacturing.mp4'), img: require('../assets/industries_served/manufacturing.jpg'), description: "Whether you own a Custom Furniture Making Business, Artisan Goods Manufacturing, Craft Brewery, and/or Small-scale Manufacturing, enhance your manufacturing business with targeted digital marketing, robust IT systems, meticulous accounting, and by implementing energy-saving measures.", industry: "manufacturing" },
  { title: "Professional Services Industry", src: require('../assets/industries_served/professional.mp4'), img: require('../assets/industries_served/professional.jpg'), description: "Whether you provide Engineering Services, Architecture Services, Management Services, Legal Services, and/or Career Coaching, elevate your professional services with strategic digital marketing, advanced IT systems, and precise bookkeeping/accounting.", industry: "professional" },
  { title: "Real Estate", src: require('../assets/industries_served/realestate.mp4'), img: require('../assets/industries_served/realestate.jpg'), description: "Whether you are owner of Real Estate Brokerage, Property Management, Real Estate Investment, Home Staging, and/or Vacation Rental, drive your real estate business forward with effective digital marketing, advanced website solutions, and enhanced branding with our help.", industry: "realestate" },
  { title: "Retail Industry", src: require('../assets/industries_served/retail.mp4'), img: require('../assets/industries_served/retail.jpg'), description: "Increase your retail presence with targeted digital marketing, and seamless website solutions. Improve your operations by optimizing your resources and energy usage, and enhanced branding with our expertise.", industry: "retail" },
  { title: "Technology", src: require('../assets/industries_served/technology.mp4'), img: require('../assets/industries_served/technology.jpg'), description: "Leverage our expertise in digital marketing, IT systems, accurate bookkeeping/accounting, and/or adopting sustainable energy practices to elevate your technology business.", industry: "technology" },
  { title: "Travel & Hospitality Industry", src: require('../assets/industries_served/travel.mp4'), img: require('../assets/industries_served/travel.jpg'), description: "Whether you own a Travel Agency, Bed & Breakfast, Event Planning Agency, Tour Guide Services, or Vacation Property Management, let us help you attract more travellers with effective digital marketing, user-friendly websites, enhanced brand identity, and/or by promoting sustainability.", industry: "travel" },
  { title: "Consulting Services Business", src: require('../assets/industries_served/consulting.mp4'), img: require('../assets/industries_served/consulting.jpg'), description: "We provide comprehensive digital marketing strategies, cutting-edge IT systems and website development, and meticulous accounting/bookkeeping services tailored for your consulting business.", industry: "consulting" },
  { title: "Creative Services & Art Business", src: require('../assets/industries_served/creativeservices.mp4'), img: require('../assets/industries_served/creativeservices.jpg'), description: "Let us amplify your creative business with bespoke digital marketing strategies, innovative website design, and comprehensive accounting/finance solutions that let you focus on your craft.", industry: "creative" },
  { title: "E-commerce Business", src: require('../assets/industries_served/ecommerce.mp4'), img: require('../assets/industries_served/ecommerce.jpg'), description: "Whether you own Online Retail Store, Dropshipping Business, Subscription Box Service, Handmade Products Store, or Print-on-demand Store, enhance your online store with targeted digital marketing, seamless website development, precise financial management, and/or other business operational needs.", industry: "ecommerce" },
  { title: "Education & Training", src: require('../assets/industries_served/education.mp4'), img: require('../assets/industries_served/education.jpg'), description: "With our help reach more students with digital marketing, develop robust online platforms, and maintain accurate financial records.", industry: "education" },
  { title: "Non-Profit & Government", src: require('../assets/industries_served/govt.mp4'), img: require('../assets/industries_served/govt.jpg'), description: "Enhance your impact and streamline operation with our comprehensive services.", industry: "non-profit_government" },
];

const Home = () => {
  const [animationClass, setAnimationClass] = useState('hero-content');
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const playTimeoutRef = useRef(null);
  const pauseTimeoutRef = useRef(null);

  useEffect(() => {
    // Scroll to top when the component is mounted or location changes
    window.scrollTo(0, 0);

    if (location.pathname === '/') {
      // Trigger re-animation
      setAnimationClass('hero-content reset-animation');
      setTimeout(() => {
        setAnimationClass('hero-content');
      }, 100); // Timeout for re-triggering animation
    }
  }, [location]);

  const handleVideoClick = (video) => {
    setSelectedVideo(video);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMouseOver = (event) => {
    clearTimeout(pauseTimeoutRef.current);
    playTimeoutRef.current = setTimeout(() => {
      event.target.play();
    }, 100); // Adding a small delay to prevent rapid play/pause
  };

  const handleMouseOut = (event) => {
    clearTimeout(playTimeoutRef.current);
    pauseTimeoutRef.current = setTimeout(() => {
      event.target.pause();
      event.target.currentTime = 0;
    }, 100); // Adding a small delay to prevent rapid play/pause
  };

  const handleExploreClick = () => {
    if (selectedVideo && selectedVideo.industry) {
      navigate(`/portfolio/${selectedVideo.industry}`);
    }
  };

  const formRef = useRef(null);
  const handleSubscribeSubmit = (event) => {
    event.preventDefault();
    const form = formRef.current;
    const formData = new FormData(form);
    const action = form.action;

    fetch(action, {
      method: 'POST',
      body: formData,
      mode: 'no-cors',
    }).then(() => {
      form.reset();
      alert('Form submitted successfully!');
    }).catch((error) => {
      console.error('Error:', error);
    });
  };

  return (
    <Container disableGutters maxWidth={false}>
      <Helmet>
        <title>Home - Stratagemix Consulting</title>
        <meta name="google-site-verification" content="2shSZi1AoGfmRKimeRjufwkR7s28D9XL16eonsXvHPw" />
        <meta name="description" content="Stratagemix Consulting provides expert business consulting services to help businesses optimize their operations, enhance their strategies, and achieve their financial and decarbonization goals." />
        <meta name="keywords" content="business consulting, digital marketing, IT services, website development, bookkeeping, accounting, energy optimization" />
        <meta property="og:title" content="Home - Stratagemix Consulting" />
        <meta property="og:description" content="Stratagemix Consulting provides expert business consulting services to help businesses optimize their operations, enhance their strategies, and achieve their financial and decarbonization goals." />
        <meta property="og:image" content="https://www.example.com/path-to-your-image.jpg" />
        <meta property="og:url" content="https://www.stratagemixconsulting.com/" />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content="Home - Stratagemix Consulting" />
        <meta name="twitter:description" content="Stratagemix Consulting provides expert business consulting services to help businesses optimize their operations, enhance their strategies, and achieve their financial and decarbonization goals." />
        <meta name="twitter:image" content="https://www.example.com/path-to-your-image.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Box className="hero-section">
        <Box className={animationClass}>
          <Box mb={2}>
            <Typography variant="h2">Stratagemix Consulting</Typography>
          </Box>
          <Box mb={2}>
            <Typography variant="h5">Your success is our business.</Typography>
          </Box>
          <Box className="lottie-button">
            <Lottie 
              animationData={buttonAnimation} 
              onClick={() => window.location.href = "/contact"} 
            />
          </Box>
        </Box>
      </Box>

      <Box className="about-section">
        <Container>
          <Grid container spacing={4} alignItems="center" className='H-about-content'>
            <Grid item xs={12} md={6}>
              <Typography variant="h2">About Us</Typography>
              <Typography variant="body1">
                Stratagemix Consulting provides expert business consulting services. We can help
                businesses optimize their operations, enhance their strategies, and/or achieve their financial, digital marketing, and decarbonization goals.
              </Typography>
              <Button variant="contained" color="secondary" href="/about" className='learn-more-button'>
                Learn More
              </Button>
            </Grid>
            <Grid item xs={12} md={6} className="lottie-animation-container">
              <Lottie animationData={lottieAnimation} style={{ height: '300px', width: '100%' }} />
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box className="industries-section">
        <Container>
          <Typography variant="h2">
            Industries & Businesses We Serve
          </Typography>
          <Typography variant="h3">
            Businesses   |   Startups   |   Govt   |   Non-Profits   |   Individuals
          </Typography>
          <Grid container spacing={4}>
            {videoData.map((video, index) => (
              <Grid item xs={12} md={4} key={index} onClick={() => handleVideoClick(video)}>
                <Box className="video-tile">
                  <video 
                    className="video-content" 
                    loop 
                    muted 
                    onMouseOver={handleMouseOver} 
                    onMouseOut={handleMouseOut}
                  >
                    <source src={video.src} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <img src={video.img} alt={video.title} className="image-content" />
                  <Typography variant="h6" className="video-title">{video.title}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
      <Box className="subscribe-section">
        <Container>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6} className="d-flex justify-content-start">
              <img src={logo} alt="STRATAGEMIX" className="subscribe-logo" />
            </Grid>
            <Grid item xs={12} md={6}>
              <form ref={formRef} className="subscribe-form" action='https://docs.google.com/forms/d/e/1FAIpQLSdxkaBNsPMTct62p250JtW0eOOITluOw4JaxqW5PPKrKQInjg/formResponse' method="POST" target="_blank" onSubmit={handleSubscribeSubmit}>
                <Typography variant="h4" className="subscribe-title">SUBSCRIBE</Typography>
                <input className="subscribe-input" type="email" name="entry.557918144" placeholder="Email" required aria-label="Email" />
                <Button
                  variant="contained"
                  type="submit"
                  className="subscribe-button"
                >
                  SUBSCRIBE
                </Button>
              </form>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{selectedVideo?.title}</DialogTitle>
        <DialogContent className='ISdialog-content'>
          <Typography variant="body1">{selectedVideo?.description}</Typography>
        </DialogContent>
        <Box textAlign="center" marginTop={2}>
          <Button variant="contained" className='dialog-explore-button' onClick={handleExploreClick}>
            Explore
          </Button>
        </Box>
      </Dialog>
    </Container>
  );
};

export default Home;
