// src/components/Shop.js
import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Shop.css';

const Shop = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
    script.onload = () => {
      if (window.ShopifyBuy) {
        if (window.ShopifyBuy.UI) {
          ShopifyBuyInit();
        }
      }
    };
    document.body.appendChild(script);
  }, []);

  const ShopifyBuyInit = () => {
    const client = window.ShopifyBuy.buildClient({
      domain: '9383bd-4f.myshopify.com',
      storefrontAccessToken: 'YOUR_ACCESS_TOKEN',
    });
    window.ShopifyBuy.UI.onReady(client).then((ui) => {
      ui.createComponent('product', {
        id: 'PRODUCT_ID',
        node: document.getElementById('shopify-buy'),
        moneyFormat: '%24%7B%7Bamount%7D%7D',
        options: {
          product: {
            buttonDestination: 'checkout',
            layout: 'vertical',
            iframe: false,
            templates: {
              button: '<button class="shopify-buy-button">Buy Now</button>',
            },
            styles: {
              button: {
                'background-color': '#6D8661',
                ':hover': {
                  'background-color': '#4a6048',
                },
                'font-size': '1rem',
                'padding': '10px 20px',
                'border-radius': '4px',
                'color': '#FFFFFF',
                'cursor': 'pointer',
                'border': 'none',
              },
            },
          },
        },
      });
    });
  };

  return (
    <Container className="shop-container">
      <Row className="shop-header">
        <Col>
          <h1>Shop</h1>
        </Col>
      </Row>
      <Row className="shop-content">
        <Col>
          <div id="shopify-buy"></div>
        </Col>
      </Row>
    </Container>
  );
};

export default Shop;
