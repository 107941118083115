import React, { useEffect } from 'react';
import { Container, Typography, Box, Card, CardContent, CardMedia, Divider, Button, Grid } from '@mui/material';
import './FinancePortfolio.css';
import appmaxImage from './img-assets/appmax.svg';
import salahkaroImage from './img-assets/salahkaro.png';

const FinancePortfolio = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container className="portfolio-container">
      <Box my={4} textAlign="center">
        <Typography variant="h3" gutterBottom className="portfolio-title">Finance Portfolio</Typography>
        <Typography variant="body1" paragraph className="portfolio-description">
          Explore our showcased applications developed for the finance industry. Our solutions are designed to streamline financial processes, enhance user experience, and drive financial growth.
        </Typography>

        <Divider className="portfolio-divider" />

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Card className="app-card">
              <CardMedia
                component="img"
                height="300"
                image={appmaxImage}
                alt="Appmax"
                className="app-image"
              />
              <CardContent className="app-content">
                <Typography variant="h5" component="div" className="app-title">Appmax</Typography>
                <Typography variant="body2" color="textSecondary" className="app-subtitle">Finance Application</Typography>
                <Typography variant="body2" paragraph className="app-detail">
                  Platform: Android & iOS
                </Typography>
                <Typography variant="body2" paragraph className="app-detail">
                  Features: Secure Transactions, Real-time Updates
                </Typography>
                <Button variant="contained" color="primary" href="https://appmax.com/" target="_blank" rel="noopener" className="app-link">
                  Visit Website
                </Button>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card className="app-card">
              <CardMedia
                component="img"
                height="300"
                image={salahkaroImage}
                alt="Salahkaro"
                className="app-image"
              />
              <CardContent className="app-content">
                <Typography variant="h5" component="div" className="app-title">Salahkaro</Typography>
                <Typography variant="body2" color="textSecondary" className="app-subtitle">Financial Advisory Services</Typography>
                <Typography variant="body2" paragraph className="app-detail">
                  Platform: Web
                </Typography>
                <Button variant="contained" color="primary" href="https://salahkaro.com/" target="_blank" rel="noopener" className="app-link">
                  Visit Website
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default FinancePortfolio;
