import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import './HealthPortfolio.css';

const HealthPortfolio = () => {
  return (
    <Container>
      <Box my={4}>
        <Typography variant="h3" gutterBottom>Health & Wellness Industry Portfolio</Typography>
        <Typography variant="body1">
          Details about health and wellness projects...
        </Typography>
      </Box>
    </Container>
  );
};

export default HealthPortfolio;
