// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import About from './components/About';
import Services from './components/Services';
import Contact from './components/Contact';
import News from './components/News';
import Shop from './components/Shop';
import FinancePortfolio from './components/Portfolio/FinancePortfolio';
import FoodPortfolio from './components/Portfolio/FoodPortfolio';
import HealthPortfolio from './components/Portfolio/HealthPortfolio';
import HomePortfolio from './components/Portfolio/HomePortfolio';
import ManufacturingPortfolio from './components/Portfolio/ManufacturingPortfolio';
import ProfessionalPortfolio from './components/Portfolio/ProfessionalPortfolio';
import RealEstatePortfolio from './components/Portfolio/RealEstatePortfolio';
import RetailPortfolio from './components/Portfolio/RetailPortfolio';
import TechnologyPortfolio from './components/Portfolio/TechnologyPortfolio';
import TravelPortfolio from './components/Portfolio/TravelPortfolio';
import ConsultingPortfolio from './components/Portfolio/ConsultingPortfolio';
import CreativePortfolio from './components/Portfolio/CreativePortfolio';
import EcommercePortfolio from './components/Portfolio/EcommercePortfolio';
import EducationPortfolio from './components/Portfolio/EducationPortfolio';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Router>
      <div>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/news" element={<News />} />
          <Route path="/shop" element={<Shop />} /> {/* Added Shop route */}
          <Route path="/portfolio/finance" element={<FinancePortfolio />} />
          <Route path="/portfolio/food" element={<FoodPortfolio />} />
          <Route path="/portfolio/health" element={<HealthPortfolio />} />
          <Route path="/portfolio/home" element={<HomePortfolio />} />
          <Route path="/portfolio/manufacturing" element={<ManufacturingPortfolio />} />
          <Route path="/portfolio/professional" element={<ProfessionalPortfolio />} />
          <Route path="/portfolio/realestate" element={<RealEstatePortfolio />} />
          <Route path="/portfolio/retail" element={<RetailPortfolio />} />
          <Route path="/portfolio/technology" element={<TechnologyPortfolio />} />
          <Route path="/portfolio/travel" element={<TravelPortfolio />} />
          <Route path="/portfolio/consulting" element={<ConsultingPortfolio />} />
          <Route path="/portfolio/creative" element={<CreativePortfolio />} />
          <Route path="/portfolio/ecommerce" element={<EcommercePortfolio />} />
          <Route path="/portfolio/education" element={<EducationPortfolio />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
